import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import "./index.css";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants";
import Form from "react-bootstrap/Form";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import Texterror from "../Texterror";
import DatePicker from "react-datepicker";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import Ico1 from "../../assets/img/edit.png";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  TargetValidation
} from "../../utils/validator";
import { ThemeContext } from "../../providers/ThemeProvider";
import { MdOutlineDeleteOutline } from "react-icons/md";
import Swal from "sweetalert2";

function DetailModal(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [themeMode, setTheme] = useContext(ThemeContext);
  const customer_code = useSelector((state) => state.compensate.customerCode);
  const [entries, setEntries] = useState([]);
  const [targetYearData, setTargetYearData] = useState([]);
  const [payperiodList, setPayperiodList] = useState([]);
  const [payperiodYearValue, setPayperiodYearValue] = useState({});
  const [payperiodDateValue, setPayperiodDateValue] = useState({});
  const [targetList, setTargetList] = useState([]);
  const [formData, setFormData] = useState({ date: "", target: "" });
  const [editIndex, setEditIndex] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editMode, setEditMode] = useState(false); 
  const [searchActive, setSearchActive] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [isInitialData, setISInitialData] = useState(false);

   const [initialValues, setInitialValues] = useState({
     target: "",
     payperiod_id: "",
     payperiod_year: "",
   });
   
    const formatDateToDDMMYYYY = (dateString) => {
      return moment(dateString, "DD-MM-YYYY")?.format("MM/DD/YYYY");
    };



  const targetYearList = async () => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/costcentertarget/ppFiscalYearList`,
        {
          customer_code: customer_code,
        }
      );

      console.log("pp", data);
      

      setTargetYearData(
        data.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
      setPayperiodYearValue({
        label: data?.data[0],
        value: data?.data[0],
      });
      
      
      if(data?.data[0]) {
        targetPeriod(data?.data[0]);
      }

    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setIsLoading(false);
    }
  };
      

  const targetPeriod = async (year) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/costcentertarget/payperiodList`,
        {
          customer_code: customer_code,
          fiscal_year: year,
          cost_center_number: props?.costCenterNumber,
        }
      );

      const payPeriodData = data.data?.map((val) => {
        return {
          label: `${val?.name} - ${formatDateToDDMMYYYY(
            val?.begin_date
          )} - ${formatDateToDDMMYYYY(val?.end_date)}`,
          value: val?.end_date,
        };
      });

       const disabledAfterValue = data?.last_target_entered_date;
       const disabledDate = new Date(
         disabledAfterValue?.split("-")?.reverse()?.join("-")
       );
       const updatedPayperiodList = payPeriodData?.map((item) => {
         const itemDate = new Date(item?.value.split("-").reverse().join("-"));
         return {
           ...item,
           isDisabled: itemDate <= disabledDate,
         };
       });
       setPayperiodList(updatedPayperiodList);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  


   const getTargetDetail = async (id) => {
     try {
       const resp = await axios.get(
         `${API_CALL_URL_PRIVATE}/costcentertarget/detail/${id}?customer_code=${customer_code}`
       );

          const Detail = resp?.data?.data[0];
          setInitialValues((prev) => {
            return {
              ...prev,
              target: Detail?.target || "",
              payperiod_year: Detail?.fiscal_year || "",
              payperiod_id: Detail?.end_date,
            };
          });

           setPayperiodYearValue({
             label: Detail?.fiscal_year,
             value: Detail?.fiscal_year,
           });
           
             setPayperiodDateValue({
               label: ` ${Detail?.pay_period_name} - ${formatDateToDDMMYYYY(
                 Detail?.start_date
               )} - ${formatDateToDDMMYYYY(Detail?.pay_period_end_date)}`,
               value: Detail?.pay_period_end_date,
             });
           setEditMode(true);
           setEditId(id);
           

     } catch (error) {
       console.error("Failed to fetch user data:", error);
     } finally {
       setIsLoading(false);
     }
   };

  

  useEffect(() => {
    if (props.show) {
      setEditMode(false);
      targetYearList();
      getTargetHistoryList();
    }
  }, [props.show]);

    const onSubmit = async (values, { resetForm }) => {
      console.log("values", values);

      const obj = {
        cost_center_number: props?.costCenterNumber,
        target: values?.target,
        end_date: values?.payperiod_id,
        fiscal_year: payperiodYearValue?.value,
        customer_code: customer_code,
      };




      try {
        // setIsLoading(true);

         if (editMode) {
            // Update target
          const resp = await axios.put(
            `${API_CALL_URL_PRIVATE}/costcentertarget/update/${editId}`, obj
          );
          toast.success(resp?.data?.message || "Updated successfully!");

            setInitialValues((prev) => {
              return {
                ...prev,
                target: "",
                payperiod_year: "",
                payperiod_id: "",
              };
            });

        } else {
           const resp = await axios.post(
             `${API_CALL_URL_PRIVATE}/costcentertarget/create`,
             obj
           );

            toast.success(resp?.data?.message || "Added successfully!");
            resetForm();
        }

        // resetForm();
        setPayperiodYearValue({});
        setPayperiodDateValue({});
        setEditMode(false);
        setEditId(null);
        getTargetHistoryList();
        targetYearList();
        // toast.success(resp?.data.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        // });
        // navigate("/admin/facility");
      } catch (error) {
        // resetForm();
        // setInitialValues((prev) => {
        //   return {
        //     target: "",
        //     payperiod_id: "",
        //     payperiod_year: "",
        //   };
        // });
        // setPayperiodYearValue({});
        // setPayperiodDateValue({});
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getTargetHistoryList = async () => {
      try {
        setIsLoading(true)
        const resp = await axios.get(
          `${API_CALL_URL_PRIVATE}/costcentertarget/list/${props?.costCenterNumber}?customer_code=${encodeURIComponent(
            customer_code
          )}&sort_column=${encodeURIComponent(
            searchActive ? "" : sortKey
          )}&sort_order=${encodeURIComponent(
            searchActive ? -1 : sortOrder
          )}&limit=${encodeURIComponent(pageLimit)}&page=${encodeURIComponent(
            searchActive ? 1 : currentPage
          )}`
        );



        const Data = resp?.data?.data?.data;
        setTargetList(Data);
        setISInitialData(true);
        // setSearchActive(false);
        // setCustomerData(Data);
        // setPageLimit(Data.limit ? Data.limit : 50);
        // setTotalPages(Data?.totalPages);
        // setCurrentPage(Data.page ? Data.page : 1);
        // setHasNext(Data?.hasNextPage);
        // setHasPrev(Data?.hasPrevPage);
        // setPageCounter(Data?.pagingCounter);
        // setListLoader(false);
        // setISInitialData(true);
        // dispatch(setLoadingPage(false));
      } catch (error) {
        console.log(error);
      }
    };


    console.log("ip", initialValues);

    

// const customStyles = () => ({
//   control: (base, state) => ({
//     ...base,
//     ...(state.isDisabled && {
//       backgroundColor: themeMode.theme === "DARK" ? "#444" : "#f5f5f5", // Background for disabled state
//       borderColor: themeMode.theme === "DARK" ? "#666" : "#ccc", // Border for disabled state
//       pointerEvents: "none", // Disable interaction
//     }),
//   }),
//   singleValue: (base, state) => ({
//     ...base,
//     ...(state.isDisabled && {
//       color: themeMode.theme === "DARK" ? "#aaa" : "#888", // Text color for disabled state
//     }),
//   }),
//   option: (base, state) => ({
//     ...base,
//     color: state.data.isDisabled
//       ? themeMode.theme === "DARK"
//         ? "#666"
//         : "#aaa"
//       : base.color,
//     backgroundColor: state.isFocused
//       ? state.data.isDisabled
//         ? "inherit"
//         : "#f0f0f0"
//       : "white",
//     cursor: state.data.isDisabled ? "not-allowed" : "pointer",
//     pointerEvents: state.data.isDisabled ? "none" : "auto", // Ensure disabled options are non-clickable
//   }),
// });


const customStyles = () => ({
  control: (base, state) => ({
    ...base,
    ...(state.isDisabled && {
      backgroundColor: themeMode.theme === "DARK" ? "#444" : "#f5f5f5", // Background for disabled state
      borderColor: themeMode.theme === "DARK" ? "#666" : "#ccc", // Border for disabled state
      pointerEvents: "none", // Disable interaction
    }),
  }),
  singleValue: (base, state) => ({
    ...base,
    ...(state.isDisabled && {
      color: themeMode.theme === "DARK" ? "#aaa" : "#888", // Text color for disabled state
    }),
  }),
  // option: (base, state) => ({
  //   ...base,
  //   color: state.data.isDisabled
  //     ? themeMode.theme === "DARK"
  //       ? "#666"
  //       : "#aaa"
  //     : base.color,
  //   backgroundColor: state.isFocused
  //     ? state.data.isDisabled
  //       ? "inherit"
  //       : "#f0f0f0"
  //     : "white",
  //   cursor: state.data.isDisabled ? "not-allowed" : "pointer",
  //   pointerEvents: state.data.isDisabled ? "none" : "auto", // Ensure disabled options are non-clickable
  // }),
});


  const targetDelete = async (id) => {
    document.body.style.overflow = "hidden";
    Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to delete the target`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      customClass: {
        container: "custom-swal",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        // setListLoader(true);
        // setIsCommentDelete(true);
        // setCustomerData([]);
        try {
          const { data } = await axios.delete(
            `${API_CALL_URL_PRIVATE}/costcentertarget/delete/${id}`
          );
          targetYearList();
          getTargetHistoryList();
          // setListLoader(false);
          toast.success("Record Deleted Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } catch (error) {
          // setIsCommentDelete(false);
          toast.error(error?.response?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      }
    });
  };

   
   





    
    
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="custom-font-size">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fw-normal color-black f-18 text-center"
        >
          {editMode ? "Update Target" : "Add Target"}
        </Modal.Title>
      </Modal.Header>

      <>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={TargetValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card border-0">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div class="row">
                            <Form.Group className="col-md-2 mb-3">
                              <Form.Label>Fiscal Year</Form.Label>
                              <Select
                                className="select-style"
                                value={payperiodYearValue}
                                options={targetYearData}
                                styles={customStyles()}
                                isDisabled={editMode}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "payperiod_year",
                                    e.value
                                  );
                                  targetPeriod(e.value);
                                  setPayperiodYearValue(e);
                                }}
                              />
                            </Form.Group>
                            <Form.Group className="col-md-5 mb-3">
                              <Form.Label>Starting Pay Period</Form.Label>
                              <Select
                                className="select-style"
                                value={payperiodDateValue}
                                options={payperiodList}
                                styles={customStyles()}
                                isOptionDisabled={(option) => option.isDisabled}
                                isDisabled={editMode}
                                onChange={(e) => {
                                  formik.setFieldValue("payperiod_id", e.value);
                                  setPayperiodDateValue({
                                    label: e?.label,
                                    value: e?.value,
                                  });
                                }}
                              />
                              <ErrorMessage
                                name="payperiod_id"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-2 mb-4"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Target</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="target"
                                value={formik.values.target}
                                {...formik.getFieldProps("target")}
                              />
                              <ErrorMessage
                                name="target"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group className="col-md-3 d-flex align-items-center mt-2 ">
                              <Button
                                // className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {"Submit"}
                              </Button>

                              {editMode && (
                                <Button
                                  className="ms-3"
                                  variant="danger"
                                  type="submit"
                                  disabled={isLoading}
                                  onClick={() => {
                                    setEditMode(false);
                                    setEditId(null);
                                    setInitialValues({
                                      target: "",
                                      payperiod_year: "",
                                      payperiod_id: "",
                                    });
                                    setPayperiodYearValue({});
                                    setPayperiodDateValue({});
                                    targetYearList();
                                  }}
                                >
                                  Cancel
                                </Button>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>

        <div>
          <div className="table-responsive m-4">
            <table className="table filter-table">
              <thead>
                <tr>
                  <th className="th-cursor">No.</th>
                  <th className="th-cursor">Target</th>
                  <th className="th-cursor">Starting Pay Period</th>
                  <th className="th-cursor">End Date</th>
                  <th className="th-cursor">Action</th>
                </tr>
              </thead>
              {isInitialData && !isLoading ? (
                <>
                  {targetList?.length > 0 ? (
                    <tbody>
                      {targetList?.map((Ele, i) => {
                        return (
                          <tr key={Ele?._id}>
                            <td>{i + 1}</td>
                            <td>{Ele?.target}</td>
                            <td>{`${
                              Ele?.pay_period_name ? Ele?.pay_period_name : ""
                            }
                            ${Ele?.pay_period_name ? "-" : ""}
                            ${formatDateToDDMMYYYY(Ele?.start_date)}
                            ${Ele?.pay_period_end_date ? "-" : ""}
                            ${
                              Ele?.pay_period_end_date
                                ? formatDateToDDMMYYYY(Ele?.pay_period_end_date)
                                : ""
                            }
                            `}</td>
                            <td>
                              {Ele?.end_date
                                ? formatDateToDDMMYYYY(Ele?.end_date)
                                : ""}
                            </td>
                            {Ele?.disable_delete_edit ? (
                              <td>
                                <label className="edit">
                                  <div
                                    className="boxlink"
                                    style={{
                                      cursor:
                                        Ele?.start_date === "01-01-1900"
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                    onClick={() => {
                                      if (Ele?.start_date !== "01-01-1900") {
                                        getTargetDetail(Ele?._id);
                                      }
                                    }}
                                    // to={`/admin/settings/edit-settings/${Ele?._id}`}
                                  >
                                    <img
                                      src={Ico1}
                                      className="w-a"
                                      alt="icon"
                                    />
                                  </div>
                                </label>
                                <label
                                  className="edit"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  <MdOutlineDeleteOutline
                                    size={23}
                                    style={{
                                      cursor:
                                        Ele?.start_date === "01-01-1900"
                                          ? "not-allowed"
                                          : "pointer",
                                      color: "red",
                                    }}
                                    onClick={() => {
                                      if (Ele?.start_date !== "01-01-1900") {
                                        targetDelete(Ele?._id);
                                      }
                                    }}
                                    // onClick={() => {
                                    //   commentDelete(Ele?._id);
                                    // }}
                                  />
                                </label>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="10">
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "200px" }}
                          >
                            <p class="text-secondary display-6">
                              Oops! Data Not found
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </>
              ) : (
                <>
                  <tbody>
                    <tr>
                      <td colSpan="10">
                        <div
                          className="d-flex align-items-center justify-content-center w-100"
                          style={{ height: "200px" }}
                        >
                          <span
                            class="loader"
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                          ></span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default DetailModal;
