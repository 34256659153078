import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "../../axios";
import { API_CALL_URL_PRIVATE } from "../../constants/compensate";
import { customerListValidation } from "../../utils/validator";
import Texterror from "../Texterror";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import parsePhoneNumberFromString from "libphonenumber-js";

function AddCustomerForm() {
  const darkInputRef = useRef(null);
  const lightInputRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [TimezoneOptions, setTimezoneOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    customer_code: "",
    customer_name: "",
    customer_number: "",
    country_code: "",
    customer_address: "",
    customer_email: "",
    dark_logo: "",
    light_logo: "",
    customer_status: "1",
    show_ps_logo: 0,
    show_calculated_data: 0,
    pped_in_dropdown: "",
    phone_no: "",
    y_axis_custom_value: "0",
    show_employee_name_on_home: "1",
    // show_new_realtime_dashboard: "0",
    dynamic_shift_interval: "1",
    timezone: "",
    label_setting: [],
    auto_logout_time: 0,
    fy_sequence_reset_day: 14,
    fy_sequence_reset_month: 9,
    no_of_days_in_payperiod: 14,
  });
  const [selectedDarkImage, setSelectedDarkImage] = useState(null);
  const [selectedLightImage, setSelectedLightImage] = useState(null);
  const [darkLoader, setDarkLoader] = useState(false);
  const [lightLoader, setLightLoader] = useState(false);
  const [value, setValue] = useState("");
  const [isInitialData, setISInitialData] = useState(false);
  const dateSequence = Array.from({ length: 31 }, (_, i) => i + 1);
  const monthSequence = Array.from({ length: 12 }, (_, i) => i + 1);

  const checkFormFields = async (values) => {
    return new Promise((resolve, reject) => {
      for (const item of values.label_setting) {
        if (item.display_and_sort === 0) {
          continue;
        }

        if (!item.field_label || item.field_label.trim() === "") {
          reject(new Error("All Label fields are mandatory"));
          return;
        }
      }

      resolve("All fields are valid");
    });
  };

  function separateCountryCodeAndNumber(fullPhoneNumber) {
    const phoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
    if (!phoneNumber) {
      return { countryCode: "", nationalNumber: "" };
    }
    return {
      countryCode: phoneNumber.countryCallingCode,
      nationalNumber: phoneNumber.nationalNumber,
    };
  }
  useEffect(() => {
    const getTimezoneOptions = async () => {
      setISInitialData(false);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/general/timezoneMaster`
      );

      setTimezoneOptions(resp.data.data);
      setISInitialData(true);
    };
    const getLabelSetting = async () => {
      setISInitialData(false);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/customer/labelSetting`
      );
      setInitialValues((prev) => {
        return {
          ...prev,
          label_setting: resp?.data?.label_setting,
        };
      });

      setISInitialData(true);
    };
    getTimezoneOptions();
    getLabelSetting();
  }, []);

  const handlePhoneChange = (ph, formik) => {
    const phone = ph || "";

    formik.setFieldValue("phone_no", phone);
    const parsedNumber = parsePhoneNumberFromString(phone);
    if (parsedNumber) {
      formik.setFieldValue("customer_number", parsedNumber.nationalNumber);
      formik.setFieldValue(
        "country_code",
        `+${parsedNumber.countryCallingCode}`
      );
    } else {
      formik.setFieldValue("customer_number", "");
      formik.setFieldValue("country_code", "");
    }
  };
  const onSubmit = async (values, onSubmittingProps) => {
    console.log("form Values", values);
    try {
      await checkFormFields(values);
      formSubmit(values);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
    // formSubmit(values);
    // setTimeout(() => {
    //   onSubmittingProps.setSubmitting(false);
    // }, [2000]);
  };
  const formSubmit = async (values) => {
    const obj = {
      customer_code: values.customer_code,
      customer_name: values.customer_name,
      customer_number: values.customer_number,
      customer_email: values.customer_email,
      // country_code: values.country_code,
      address: values.customer_address,
      status: parseInt(values.customer_status),
      pped_in_dropdown: parseInt(values.pped_in_dropdown),
      logo_darkmode: values.dark_logo,
      logo_lightmode: values.light_logo,
      show_ps_logo: values.show_ps_logo,
      show_calculated_data: values.show_calculated_data,
      y_axis_custom_value: parseInt(values.y_axis_custom_value),
      show_employee_name_on_home: parseInt(values.show_employee_name_on_home),
      // show_new_realtime_dashboard: parseInt(values.show_new_realtime_dashboard),
      dynamic_shift_interval: parseInt(values.dynamic_shift_interval),
      timezone: values.timezone,
      label_setting: values.label_setting,
      auto_logout_time: parseInt(values.auto_logout_time),
      fy_sequence_reset_day: +values.fy_sequence_reset_day,
      fy_sequence_reset_month: +values.fy_sequence_reset_month,
      no_of_days_in_payperiod: +values.no_of_days_in_payperiod,
    };
    try {
      setIsLoading(true);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/customer/customerAddUpdate`,
        obj
      );
      console.log(resp);
      toast.success(resp?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      navigate("/admin/customer");
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLightLogoChange = async (e, formik) => {
    formik.setFieldTouched("light_logo", true, true);

    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("logo", file);

      try {
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/customer/customerAddImage`,
          formData
        );

        if (response.status === 200) {
          console.log("Image uploaded successfully. Path:", response);
          setSelectedLightImage(response.data.data.displayPath);
          formik.setFieldValue("light_logo", response.data.data.logoPath);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return false;
        } else {
          toast.error("Failed to Upload Image..! Try Again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });

          return false;
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || error.message || "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        if (lightInputRef.current) {
          lightInputRef.current.value = "";
        }
        return false;
      }
    }
  };
  const handleDarkLogoChange = async (e, formik) => {
    formik.setFieldTouched("dark_logo", true, true);

    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("logo", file);

      try {
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/customer/customerAddImage`,
          formData
        );

        if (response.status === 200) {
          console.log("Image uploaded successfully. Path:", response);
          setSelectedDarkImage(response.data.data.displayPath);
          formik.setFieldValue("dark_logo", response.data.data.logoPath);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return false;
        } else {
          toast.error("Failed to Upload Image..! Try Again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          return false;
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || error.message || "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        if (darkInputRef.current) {
          darkInputRef.current.value = "";
        }
        return false;
      }
    }
  };

  const handleLabelSort = (key, value, formik) => {
    const NewLabelObj = formik.values.label_setting;
    const UpdatedLabelObj = NewLabelObj?.map((item) => {
      if (item?.field_name === key) {
        if (parseInt(value) === 0) {
          return { ...item, display_and_sort: 0 };
        } else return { ...item, display_and_sort: parseInt(value) };
      } else return item;
    });

    formik.setFieldValue("label_setting", UpdatedLabelObj);
  };
  // const handleLabelSort = (key, value) => {
  //   const NewLabelObj = initialValues.label_setting;
  //   const UpdatedLabelObj = NewLabelObj?.map((item) => {
  //     if (item?.field_name === key) {
  //       if (parseInt(value) === 0) {
  //         return { ...item, display_and_sort: 0 };
  //       } else return { ...item, display_and_sort: parseInt(value) };
  //     } else return item;
  //   });

  //   setInitialValues((prev) => {
  //     return {
  //       ...prev,
  //       label_setting: UpdatedLabelObj,
  //     };
  //   });
  // };

  const handleLabelName = (key, value, formik) => {
    const NewLabelObj = formik.values.label_setting;
    const UpdatedLabelObj = NewLabelObj?.map((item) => {
      if (item?.field_name === key) {
        return { ...item, field_label: value };
      } else return item;
    });

    formik.setFieldValue("label_setting", UpdatedLabelObj);
  };
  // const handleLabelName = (key, value) => {
  //   const NewLabelObj = initialValues.label_setting;
  //   const UpdatedLabelObj = NewLabelObj?.map((item) => {
  //     if (item?.field_name === key) {
  //       return { ...item, field_label: value };
  //     } else return item;
  //   });

  //   setInitialValues((prev) => {
  //     return {
  //       ...prev,
  //       label_setting: UpdatedLabelObj,
  //     };
  //   });
  // };

  return (
    <>
      {isInitialData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={customerListValidation}
          enableReinitialize
          validateOnChange
        >
          {(formik) => (
            <div className="main-panel">
              {console.log(formik)}
              <div className="content-wrapper">
                <div className="page-header">
                  <h3 className="page-title">
                    <a href="#" className="badge badge-dark"></a>
                  </h3>
                  <div className></div>
                </div>
                <div className="row">
                  <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                      <FormikForm className="forms-sample">
                        <div class="card-body">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <Link
                                to={`/admin/customer`}
                                className="f-14 white-text-dark-mode"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left " />{" "}
                                Back
                              </Link>
                            </div>
                            <div className="col-6">
                              <h4 class="card-title text-center">
                                Add Customer
                              </h4>
                            </div>
                            <div className="col-3 text-end">
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </div>
                          </div>

                          <div class="row">
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Code</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="customer_code"
                                {...formik.getFieldProps("customer_code")}
                              />
                              <ErrorMessage
                                name="customer_code"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_name"
                            >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="customer_name"
                                {...formik.getFieldProps("customer_name")}
                              />
                              <ErrorMessage
                                name="customer_name"
                                component={Texterror}
                              />
                            </Form.Group>
                            {/* <Form.Group
                            className="col-md-3 mb-3"
                            controlId="exampleForm.c_number"
                          >
                            <Form.Label>Contact Number</Form.Label>
                            <PhoneInput
                              international
                              defaultCountry="US"
                              value={formik.values.phone_no}
                              name="customer_number"
                              onChange={(e) => {
                                formik.setFieldTouched(
                                  "customer_number",
                                  true,
                                  true
                                );
                                handlePhoneChange(e, formik);
                              }}
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "customer_number",
                                  true,
                                  true
                                );
                              }}
                              className="form-control"
                            />
                            <ErrorMessage
                              name="customer_number"
                              component={Texterror}
                            />
                          </Form.Group> */}
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_number"
                            >
                              <Form.Label>Contact Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="customer_number"
                                {...formik.getFieldProps("customer_number")}
                              />
                              <ErrorMessage
                                name="customer_number"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Contact Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder=""
                                name="customer_email"
                                {...formik.getFieldProps("customer_email")}
                              />
                              <ErrorMessage
                                name="customer_email"
                                component={Texterror}
                              />
                            </Form.Group>

                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Status</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="customer_status"
                                value={formik.values.customer_status}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "customer_status",
                                    true,
                                    true
                                  );
                                  if (e.target.value !== "") {
                                    formik.setFieldValue(
                                      "customer_status",
                                      e.target.value
                                    );
                                  }
                                  // formik.setFieldValue(
                                  //   "customer_status",
                                  //   e.target.value
                                  // );
                                  // console.log(e.target.value);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "customer_status",
                                    true,
                                    true
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </Form.Select>
                              <ErrorMessage
                                name="customer_status"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Pay Period End Date</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="pped_in_dropdown"
                                {...formik.getFieldProps("pped_in_dropdown")}
                              />
                              <ErrorMessage
                                name="pped_in_dropdown"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Y axis Custom Value</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="y_axis_custom_value"
                                value={`${formik.values.y_axis_custom_value}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "y_axis_custom_value",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "y_axis_custom_value",
                                    e.target.value
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="y_axis_custom_value"
                                component={Texterror}
                              />
                            </Form.Group>
                            {/* <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>
                                Show New Realtime Dashboard
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="show_new_realtime_dashboard"
                                value={`${formik.values.show_new_realtime_dashboard}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "y_axis_custom_value",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "show_new_realtime_dashboard",
                                    e.target.value
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="show_new_realtime_dashboard"
                                component={Texterror}
                              />
                            </Form.Group> */}
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Dynamic Shift Interval</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="dynamic_shift_interval"
                                value={`${formik.values.dynamic_shift_interval}`}
                                onChange={(e) => {
                                  // formik.setFieldTouched(
                                  //   "y_axis_custom_value",
                                  //   true,
                                  //   true
                                  // );
                                  formik.setFieldValue(
                                    "dynamic_shift_interval",
                                    e.target.value
                                  );
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </Form.Select>
                              <ErrorMessage
                                name="dynamic_shift_interval"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Timezone</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="timezone"
                                value={`${formik.values.timezone}`}
                                onChange={(e) => {
                                  formik.setFieldTouched(
                                    "timezone",
                                    true,
                                    true
                                  );
                                  formik.setFieldValue(
                                    "timezone",
                                    e.target.value
                                  );
                                  console.log(e.target.value);
                                }}
                                // onChange={formik.handleChange}
                              >
                                <option value={""}>Select</option>
                                {TimezoneOptions?.map((e) => {
                                  return (
                                    <option key={e?._id} value={e?.offset}>
                                      {`${e?.timezone} ${e?.type}${e?.offset}`}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              <ErrorMessage
                                name="timezone"
                                component={Texterror}
                              />

                              <div className="mt-3">
                                <Form.Label>
                                  Auto Logout Time (In Minutes)
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder=""
                                  name="auto_logout_time"
                                  {...formik.getFieldProps("auto_logout_time")}
                                />
                                <ErrorMessage
                                  name="auto_logout_time"
                                  component={Texterror}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_email"
                            >
                              <Form.Label>Address</Form.Label>
                              <Form.Control
                                // as="textarea"
                                // rows={2}
                                type="text"
                                name="customer_address"
                                {...formik.getFieldProps("customer_address")}
                              />
                              <ErrorMessage
                                name="customer_address"
                                component={Texterror}
                              />
                              <div className="mt-3">
                                <Form.Label>
                                  Show Employee Name On Home
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  className="white"
                                  name="show_employee_name_on_home"
                                  value={`${formik.values.show_employee_name_on_home}`}
                                  onChange={(e) => {
                                    // formik.setFieldTouched(
                                    //   "show_employee_name_on_home",
                                    //   true,
                                    //   true
                                    // );
                                    formik.setFieldValue(
                                      "show_employee_name_on_home",
                                      e.target.value
                                    );
                                  }}
                                  // onChange={formik.handleChange}
                                >
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </Form.Select>
                                <ErrorMessage
                                  name="show_employee_name_on_home"
                                  component={Texterror}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group
                              controlId="formFile"
                              className="mb-0 col-md-3 "
                            >
                              <Form.Label className="w-100">
                                <div className="d-flex align-items-center  w-100">
                                  Dark Theme Logo
                                  {darkLoader && (
                                    <div className="d-flex ms-3">
                                      <span
                                        className="loader"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      ></span>
                                    </div>
                                  )}
                                </div>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                name="dark_logo"
                                ref={darkInputRef}
                                onChange={async (e) => {
                                  setDarkLoader(true);
                                  await handleDarkLogoChange(e, formik);
                                  setDarkLoader(false);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "dark_logo",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="dark_logo"
                                component={Texterror}
                              />
                              {selectedDarkImage && (
                                <div
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={selectedDarkImage}
                                    alt="Uploaded preview"
                                    style={{
                                      maxWidth: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                </div>
                              )}
                            </Form.Group>
                            <Form.Group
                              controlId="formFile"
                              className="mb-0 col-md-3 "
                            >
                              <Form.Label className="w-100">
                                <div className="d-flex align-items-center  w-100">
                                  Light Theme Logo
                                  {lightLoader && (
                                    <div className="d-flex ms-3">
                                      <span
                                        className="loader"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      ></span>
                                    </div>
                                  )}
                                </div>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                name="light_logo"
                                ref={lightInputRef}
                                onChange={async (e) => {
                                  setLightLoader(true);
                                  await handleLightLogoChange(e, formik);
                                  setLightLoader(false);
                                }}
                                onBlur={() => {
                                  formik.setFieldTouched(
                                    "light_logo",
                                    true,
                                    true
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="light_logo"
                                component={Texterror}
                              />
                              {selectedLightImage && (
                                <div
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={selectedLightImage}
                                    alt="Uploaded preview"
                                    style={{
                                      maxWidth: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                </div>
                              )}
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Fy Sequence Reset Day</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="fy_sequence_reset_day"
                                value={`${formik.values.fy_sequence_reset_day}`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "fy_sequence_reset_day",
                                    e.target.value
                                  );
                                }}
                              >
                                {dateSequence.map((date) => (
                                  <option key={date} value={date}>
                                    {date}
                                  </option>
                                ))}
                              </Form.Select>
                              <ErrorMessage
                                name="fy_sequence_reset_day"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>Fy Sequence Reset Month</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="fy_sequence_reset_month"
                                value={`${formik.values.fy_sequence_reset_month}`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "fy_sequence_reset_month",
                                    e.target.value
                                  );
                                }}
                              >
                                {monthSequence.map((month) => (
                                  <option key={month} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </Form.Select>
                              <ErrorMessage
                                name="fy_sequence_reset_month"
                                component={Texterror}
                              />
                            </Form.Group>
                            <Form.Group
                              className="col-md-3 mb-3"
                              controlId="exampleForm.c_code"
                            >
                              <Form.Label>No Of Days In Pay Period</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                className="white"
                                name="no_of_days_in_payperiod"
                                value={`${formik.values.no_of_days_in_payperiod}`}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "no_of_days_in_payperiod",
                                    e.target.value
                                  );
                                }}
                              >
                                {dateSequence.map((date) => (
                                  <option key={date} value={date}>
                                    {date}
                                  </option>
                                ))}
                              </Form.Select>
                              <ErrorMessage
                                name="no_of_days_in_payperiod"
                                component={Texterror}
                              />
                            </Form.Group>
                            <div className="d-flex">
                              <Form.Group
                                className="col-md-3 my-3"
                                controlId="exampleForm.c_email"
                              >
                                <div class="checkbox-item">
                                  <input
                                    type="checkbox"
                                    class="custom-checkbox"
                                    onChange={(p) => {
                                      if (p.target.checked) {
                                        formik.setFieldValue(
                                          "show_ps_logo",
                                          parseInt("1")
                                        );
                                      } else {
                                        formik.setFieldValue(
                                          "show_ps_logo",
                                          parseInt("0")
                                        );
                                      }
                                    }}
                                  />
                                  Show PS Logo
                                </div>
                                {/* <ErrorMessage
                              name="customer_address"
                              component={Texterror}
                            /> */}
                              </Form.Group>
                              <Form.Group
                                className="col-md-3 my-3"
                                controlId="exampleForm.c_email"
                              >
                                <div class="checkbox-item">
                                  <input
                                    type="checkbox"
                                    class="custom-checkbox"
                                    onChange={(p) => {
                                      if (p.target.checked) {
                                        formik.setFieldValue(
                                          "show_calculated_data",
                                          parseInt("1")
                                        );
                                      } else {
                                        formik.setFieldValue(
                                          "show_calculated_data",
                                          parseInt("0")
                                        );
                                      }
                                    }}
                                  />
                                  Show Calculated Data
                                </div>
                                {/* <ErrorMessage
                              name="customer_address"
                              component={Texterror}
                            /> */}
                              </Form.Group>
                            </div>
                            <div className="row mt-2">
                              <div class="col-12 grid-margin stretch-card">
                                <div class="card">
                                  <div class="card-body py-4">
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex">
                                        <h4 class="card-title fs-5 mb-0">
                                          Label Settings
                                        </h4>
                                      </div>
                                    </div>

                                    <div class="row">
                                      {/* New Design */}
                                      <div className="col-md-12 m-0">
                                        <hr />
                                      </div>
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div class="table-responsive">
                                              <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th>Show/Hide/Sort</th>
                                                    <th>Key</th>
                                                    <th>Label</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {formik.values.label_setting?.map(
                                                    (item) => {
                                                      return (
                                                        <tr
                                                          key={item?.field_name}
                                                        >
                                                          <td className="sm-transparent-input">
                                                            <input
                                                              type="text"
                                                              class="form-control"
                                                              value={
                                                                item?.display_and_sort
                                                              }
                                                              onChange={(e) => {
                                                                handleLabelSort(
                                                                  item.field_name,
                                                                  e.target
                                                                    .value !==
                                                                    ""
                                                                    ? e.target
                                                                        .value
                                                                    : "0",
                                                                  formik
                                                                );
                                                              }}
                                                            />
                                                          </td>
                                                          <td className="pe-5">
                                                            <div className="d-flex align-items-center">
                                                              <input
                                                                type="text"
                                                                class="form-control mx-w-340"
                                                                value={
                                                                  item?.field_name
                                                                }
                                                                readOnly
                                                              />
                                                            </div>
                                                          </td>

                                                          <td className="pe-5">
                                                            <div className="d-flex align-items-center">
                                                              <input
                                                                type="text"
                                                                class="form-control mx-w-340"
                                                                value={
                                                                  item?.field_label
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleLabelName(
                                                                    item?.field_name,
                                                                    e.target
                                                                      .value,
                                                                    formik
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Form.Group className="col-md-12 mb-3 d-flex justify-content-end">
                              {/* <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                formik.resetForm();
                                setSelectedImage(null);
                                // const fileInput =
                                //   document.getElementById("logo");
                                // if (fileInput) {
                                //   fileInput.value = "";
                                // }
                              }}
                            >
                              Reset
                            </Button> */}
                              <Button
                                className="ms-3"
                                variant="success"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? "Saving..." : "Save"}
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <div className="d-flex align-items-center justify-content-center w-100vw h-100vh">
          <span class="loader" style={{ width: "50px", height: "50px" }}></span>
        </div>
      )}
    </>
  );
}

export default AddCustomerForm;
